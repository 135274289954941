import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { saveUmrahDetails } from "../../apicalls/umrahDetails";
import { useSelector, useDispatch } from "react-redux";
import { fetchAgents } from "../../redux/agentsSlice";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : "";
  const { agents } = useSelector((state) => state.agents || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vendorId) {
      dispatch(fetchAgents(vendorId));
    }
  }, [dispatch, vendorId]);

  const phoneRegExp = /^[0-9]{10}$/;
  const amountRegExp = /^[0-9]+(\.[0-9]{1,2})?$/;
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => currentYear + i);

  const checkoutSchema = yup.object().shape({
    umrahName: yup
      .string()
      .required("Full name is required")
      .min(2, "Must be at least 2 characters")
      .max(50, "Must be at most 50 characters"),
    fatherName: yup.string().required("Father name is required"),
    motherName: yup.string().required("Mother name is required"),
    referencePerson: yup.string().required("Reference person is required"),
    nidNo: yup.string().required("NID No is required"),
    dateOfBirth: yup.date().required("Date of birth is required").nullable(),
    registrationDate: yup
      .date()
      .required("Registration date is required")
      .nullable(),
    year: yup.string().required("Year is required").matches(/^\d{4}$/, "Year must be a 4-digit number"),
    address: yup.string().required("Address is required"),
    serialNo: yup.string().required("Serial No is required"),
    remark: yup.string().required("Remark is required"),
    trackingNo: yup.string().required("Tracking No is required"),
    mobileNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number must be 10 digits")
      .required("Mobile Number is required"),
    billAmount: yup
      .string()
      .matches(amountRegExp, "Bill amount is not valid")
      .required("Bill amount is required"),
    passportNumber: yup
      .string()
      .required("Passport number is required")
      .min(8, "Passport number must be at least 8 characters")
      .max(20, "Passport number must be at most 20 characters"),
  });

  const initialValues = {
    umrahName: "",
    dateOfBirth: null,
    registrationDate: null,
    fatherName: "",
    passportNumber: "",
    serialNo: "",
    year: "",
    nidNo: "",
    referencePerson: "",
    address: "",
    trackingNo: "",
    billAmount: "",
    motherName: "",
    remark: "",
    typeOf: "Hajjmain", 
    mobileNumber: "",
   // vendorId: vendorId,
    status: "Unpaid",
  };
 
  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      const value = values[key] instanceof Date ? values[key].toISOString() : values[key];
      formData.append(key, value);
    });
    formData.append("vendorId", vendorId);
    try {
      await saveUmrahDetails(formData);
      message.success("Main details saved successfully!");
      resetForm();
    } catch (err) {
      console.error("Error saving Main details:", err);
      message.error("Error saving Main details. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="HAJJ MAIN-REGISTER" subtitle="Create a New Hajj Main-Register " />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                label="Full Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.umrahName}
                name="umrahName"
                error={!!touched.umrahName && !!errors.umrahName}
                helperText={touched.umrahName && errors.umrahName}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl fullWidth variant="filled" error={!!touched.Agent_id && !!errors.Agent_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="assign-user-label">Agent Name *</InputLabel>
                <Select
                  labelId="assign-user-label"
                  value={values.Agent_id}
                  onChange={handleChange}
                  name="Agent_id"
                  onBlur={handleBlur}
                >
                  <MenuItem value="" disabled>Select One</MenuItem>
                  {Array.isArray(agents) && agents.length > 0 ? (
                    agents.map(agent => (
                      <MenuItem key={agent._id} value={agent._id}>
                        {agent.AgentName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No agents available</MenuItem>
                  )}
                </Select>
                {touched.Agent_id && errors.Agent_id && <FormHelperText>{errors.Agent_id}</FormHelperText>}
              </FormControl>
              <TextField
                fullWidth
                variant="filled"
                label="Father Name *"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fatherName}
                name="fatherName"
                error={!!touched.fatherName && !!errors.fatherName}
                helperText={touched.fatherName && errors.fatherName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Reference Person"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.referencePerson}
                name="referencePerson"
                error={!!touched.referencePerson && !!errors.referencePerson}
                helperText={touched.referencePerson && errors.referencePerson}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Mother Name *"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.motherName}
                name="motherName"
                error={!!touched.motherName && !!errors.motherName}
                helperText={touched.motherName && errors.motherName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Charge Amount *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.billAmount}
                name="billAmount"
                error={!!touched.billAmount && !!errors.billAmount}
                helperText={touched.billAmount && errors.billAmount}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="NID No *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nidNo}
                name="nidNo"
                error={!!touched.nidNo && !!errors.nidNo}
                helperText={touched.nidNo && errors.nidNo}
                sx={{ gridColumn: "span 2" }}
              />
         
             <FormControl fullWidth variant="filled" error={!!touched.year && !!errors.year} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="year-year-label">Year</InputLabel>
                <Select
                  labelId="year-year-label"
                  value={values.year}
                  onChange={handleChange}
                  name="year"
                  onBlur={handleBlur}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                {touched.year && errors.year && (
                  <FormHelperText>{errors.year}</FormHelperText>
                )}
              </FormControl>
           
          
              <TextField
                fullWidth
                variant="filled"
                label="Passport Number *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passportNumber}
                name="passportNumber"
                error={!!touched.passportNumber && !!errors.passportNumber}
                helperText={touched.passportNumber && errors.passportNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="Date of Birth *"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={(e) => {
                  const dateValue = new Date(e.target.value);
                  setFieldValue('dateOfBirth', dateValue);
                }}
                value={values.dateOfBirth ? values.dateOfBirth.toISOString().split('T')[0] : ''}
                name="dateOfBirth"
                sx={{ gridColumn: "span 2" }}
                error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                helperText={touched.dateOfBirth && errors.dateOfBirth}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Phone Number *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.mobileNumber}
                name="mobileNumber"
                error={!!touched.mobileNumber && !!errors.mobileNumber}
                helperText={touched.mobileNumber && errors.mobileNumber}
                sx={{ gridColumn: "span 2" }}
              />
              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Hajj Main Registration Date *"
                  InputLabelProps={{ shrink: true }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('registrationDate', dateValue);
                  }}
                  value={values.registrationDate ? values.registrationDate.toISOString().split('T')[0] : ''}
                  name="registrationDate"
                  error={!!touched.registrationDate && !!errors.registrationDate}
                  helperText={touched.registrationDate && errors.registrationDate}
                />
              </Box>
              <TextField
                fullWidth
                variant="filled"
                label="Address *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                name="address"
                error={!!touched.address && !!errors.address}
                helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Remark *"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.remark}
                name="remark"
                error={!!touched.remark && !!errors.remark}
                helperText={touched.remark && errors.remark}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Serial No *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.serialNo}
                name="serialNo"
                error={!!touched.serialNo && !!errors.serialNo}
                helperText={touched.serialNo && errors.serialNo}
                sx={{ gridColumn: "span 2" }}
              />
            
              <input
                type="file"
                onChange={(e) => handleChange({ target: { name: 'documentFile', value: e.target.files[0] } })}
                name="documentFile"
                style={{ gridColumn: "span 2", width: "100%", padding: "16px", borderRadius: "4px", border: "1px solid #ccc" }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Tracking No *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.trackingNo}
                name="trackingNo"
                error={!!touched.trackingNo && !!errors.trackingNo}
                helperText={touched.trackingNo && errors.trackingNo}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="button"
                color="primary"
                variant="contained"
                style={{
                  backgroundColor: '#0b65e9',
                  color: '#fafafa !important',
                  borderColor: '#1976d2',
                }}
              >
                <Link to="/hajjmainregister" className="link-style">
                  Cancel
                </Link>
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" color="secondary" variant="contained">
              Create New Hajj Main-Register
              </Button>
              &nbsp;&nbsp;
              <Button type="button" color="primary" className="btn-reset" variant="outlined" onClick={resetForm}>
                Reset
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
