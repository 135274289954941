import {
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveairlinesticketDetails } from "../../apicalls/airlinesticketDetails"; 
import { message } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import   './index.css';

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            const response = await saveairlinesticketDetails(formData);
            if (response.success) {
                message.success(response.message);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("An error occurred. Please try again.");
            console.error(error);
        }
        resetForm();
    };

    const initialValues = {
        airlinesName: "",
        airlinesCode: "",
        ticketQty: "",
        ticketsSeries: "",
        isActive: 'Active', 
        vendorId: vendorId,
        ticketClass: "",
    };

    const checkoutSchema = yup.object().shape({
        airlinesName: yup.string().required("Airlines name is required"),
        ticketQty: yup.number().typeError("Invalid ticket quantity format").required("Ticket quantity is required"), 
        airlinesCode: yup.string().required("Airlines code is required"),
        ticketsSeries: yup.string().required("Ticket series is required"),
        ticketClass: yup.string().required("Ticket class selection is required"),
    });

    return (
        <Box m="20px">
            <Header title="TICKETING AIRLINES" subtitle="Create Ticketing Airlines" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            {['airlinesName', 'airlinesCode', 'ticketsSeries', 'ticketQty'].map((field, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    variant="filled"
                                    type={field === 'ticketQty' ? "number" : "text"}
                                    label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values[field]}
                                    name={field}
                                    error={!!touched[field] && !!errors[field]}
                                    helperText={touched[field] && errors[field]}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            ))}
                        </Box>

                        <Box
                            display="grid"
                            gap="30px"
                            mt="20px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <FormControl
                                fullWidth
                                variant="filled"
                                error={!!touched.ticketClass && !!errors.ticketClass}
                                sx={{ gridColumn: "span 2", mb: 2 }}
                            >
                                <InputLabel id="ticket-class-select-label">Select Ticket Class</InputLabel>
                                <Select
                                    labelId="ticket-class-select-label"
                                    value={values.ticketClass}
                                    onChange={handleChange}
                                    name="ticketClass"
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value="Economy">Economy </MenuItem>
                                    <MenuItem value="Business">Business </MenuItem>
                                    <MenuItem value="First">First Class</MenuItem>
                                </Select>
                                {touched.ticketClass && errors.ticketClass && (
                                    <FormHelperText>{errors.ticketClass}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            type="button"
                            color="primary" 
                            variant="contained"
                            style={{
                            backgroundColor: '#0b65e9', 
                            color: '#fafafa !important', 
                            borderColor: '#1976d2',
                            }}
                        >
                            <Link to="/ticketairlines" className="link-style">
                            Cancel
                            </Link>
                        </Button>&nbsp;&nbsp;
                            <Button type="submit" color="secondary" variant="contained">
                                Create New Airline Ticket
                            </Button>&nbsp;&nbsp;
                            <Button
                             className="btn-reset"
                                type="button"
                                color="primary"
                                variant="contained"
                                onClick={() => resetForm()}
                                style={{
                                    backgroundColor: '#e2e2e2', 
                                    color: '#1976d2',
                                    borderColor: '#1976d2', 
                                  }}
                            >
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
