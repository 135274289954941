import { axiosInstance } from ".";

/*** 
 * Base URL for API calls
 * Change this based on the environment (local/production).
 */
 //const BASE_URL = 'http://localhost:8000/api/users'; 
  const BASE_URL = 'http://65.1.94.207:8000/api/users'; // Uncomment for production

/**
 * Save a new permission
 * @param {Object} payload - The permission data to be saved.
 * @returns {Promise<Object>} - The response data from the server.
 */
export const savePermission = async (payload) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/save-permission`, payload);
        return response.data;
    } catch (error) {
        console.error("Error saving permission:", error);
        throw new Error("Failed to save permission. Please try again.");
    }
}

/** 
 * Fetch the list of permissions for a specific user or role.
 * @param {string} id - The ID of the user or role.
 * @returns {Promise<Object>} - The response data containing the permissions list.
 */
export const getPermissionList = async (id) => {
    try {

        const response = await axiosInstance.get(`${BASE_URL}/get-permission-data/${id}`);
        return response.data;

    } catch (error) {
        console.error("Error fetching permission list:", error);
        throw new Error("Failed to fetch permission list. Please try again.");
    }
}

/**
 * Soft delete a permission by ID.
 * @param {string} id - The ID of the permission to be deleted.
 * @returns {Promise<Object>} - The response data after deletion.
 */
export const deletePermission = async (id) => {
    try {
        const response = await axiosInstance.delete(`${BASE_URL}/delete-permission/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting permission:", error);
        throw new Error("Failed to delete permission. Please try again.");
    }
};

/**
 * Update an existing permission's details.
 * @param {string} id - The ID of the permission to be updated.
 * @param {Object} payload - The new permission data to be updated.
 * @returns {Promise<Object>} - The response data with the updated permission.
 */
export const updatePermission = async (id, payload) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/update-permission/${id}`, payload);
        return response.data;
    } catch (error) {
        console.error("Error updating permission:", error);
        throw new Error("Failed to update permission. Please try again.");
    }
};
