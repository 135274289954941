import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
    TextField, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText, RadioGroup, 
    FormLabel, FormControlLabel, Radio } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import PaymentsIcon from '@mui/icons-material/Payments';
import InfoIcon from '@mui/icons-material/Info';
import PrintIcon from '@mui/icons-material/Print';
import { message } from 'antd';
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Header from "../../components/Header";
import EditIcon from '@mui/icons-material/Edit';
import { jsPDF } from "jspdf";
import { getUmrahDetailsList, updateUmrahDetails } from "../../apicalls/umrahDetails";

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [prehajjData, setPrehajjData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false); 
    const [selectedPrehajj, setSelectedPrehajj] = useState(null); 
    const [searchQuery, setSearchQuery] = useState("");
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [groupDetails, setGroupDetails] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const type = "Hajj";
            const response = await getUmrahDetailsList(vendorId, type);
            if (response.success) {
                setPrehajjData(response.data);
            } else {
                message.error(response.message);
            }
        } catch (error) {
            message.error("Failed to fetch pre-hajj data");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]); 

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter prehajjData based on search query
    const filteredPrehajj = prehajjData.filter(prehajj => {
        return prehajj.umrahName.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const handleInfo = (prehajj) => {
        setSelectedPrehajj(prehajj); 
        setOpenInfoDialog(true); 
    };

    const handleCloseInfoDialog = () => {
        setOpenInfoDialog(false); 
    };

    const openUpdateDialogHandler = (prehajj) => {
        
        if (prehajj.status === "Paid") {
            message.warning("This record has already been submitted and cannot be updated.");
            return;
        }
        setGroupDetails(prehajj); 
        setUpdateModalOpen(true); 
    };

    const handleUpdate = async () => {
        if (!groupDetails.status) {
            message.error("Please select a status before saving.");
            return;
        }
    
        try {
            const payload = { status: groupDetails.status };
            const response = await updateUmrahDetails(groupDetails._id, payload);
            if (response.success) {
                message.success("Status updated successfully!");
                fetchData(); // Refresh the data to reflect updates
                setUpdateModalOpen(false);
            } else {
                message.error(response.message || "Failed to update status.");
            }
        } catch (error) {
            console.error("Error updating status:", error);
            message.error("An error occurred while updating the status.");
        }
    };
    const handlePrint = async (selectedPrehajj) => {
        const doc = new jsPDF();
    
        // Helper Function: Format Date
        const formatDate = (date) => {
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            return new Date(date).toLocaleDateString('en-US', options);
        };
    
        // Helper Function: Convert Number to Words
        const numberToWords = (num) => {
            const a = [
                '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten',
                'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
            ];
            const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
            const g = ['', 'Thousand', 'Million', 'Billion'];
    
            const convert = (n) => {
                if (n === 0) return 'Zero';
                let str = '';
                let group = 0;
    
                while (n > 0) {
                    let chunk = n % 1000;
                    if (chunk > 0) {
                        let hundreds = Math.floor(chunk / 100);
                        let tens = chunk % 100;
                        let units = chunk % 10;
                        let part = '';
    
                        if (hundreds > 0) part += `${a[hundreds]} Hundred `;
                        if (tens < 20) part += a[tens];
                        else part += `${b[Math.floor(tens / 10)]} ${a[units]}`;
    
                        str = `${part} ${g[group]} ${str}`;
                    }
                    group++;
                    n = Math.floor(n / 1000);
                }
    
                return str.trim();
            };
    
            return convert(num);
        };
    
        // Header Section
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        doc.text("Airbook Service Organization", 105, 10, null, null, 'center');
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.text("123 Main Street, City, Country | Phone: +123-456-7890 | Email: info@airbook.com", 105, 16, null, null, 'center');
    
        // Add Company Logo
        // const logoBase64 = 'data:image/png;base64,REPLACE_WITH_YOUR_BASE64_STRING';
        // doc.addImage(logoBase64, 'PNG', 80, 20, 50, 15);
    
        doc.setLineWidth(0.5);
        doc.line(10, 40, 200, 40);
    
        // Receipt Title
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Money Receipt", 105, 50, null, null, 'center');
        doc.setFontSize(10);
        doc.text(`Receipt No.: ${selectedPrehajj.receiptNo || 'N/A'}`, 10, 60);
        doc.text(`Date: ${formatDate(new Date())}`, 200, 60, null, null, 'right');
    
        // Table Header
        let yPosition = 70;
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Pre Hajj Payment Recipient Details", 10, yPosition);
        doc.line(10, yPosition + 2, 200, yPosition + 2); 
        yPosition += 8;
    
        // Table Rows
        doc.setFont("helvetica", "normal");
        const billAmount = selectedPrehajj.billAmount || 0;
        const paidAmount = selectedPrehajj.paidAmount || 0;
        const dueAmount = billAmount - paidAmount;
    
        const tableData = [
            ["Received From", selectedPrehajj.depositerName || 'N/A'],
            ["Voucher No", selectedPrehajj.voucherNo || 'N/A'],
            ["Received Date", selectedPrehajj.paymentDate ? formatDate(selectedPrehajj.paymentDate) : 'N/A'],
            ["Payment Type", selectedPrehajj.paymentType || 'N/A'],
            ["The Sum of Total", billAmount.toString()],
            ["Total Paid Amount", paidAmount.toString()],
            ["Due Amount", dueAmount.toString()],
            ["Payment Status", dueAmount <= 0 ? selectedPrehajj.status : selectedPrehajj.status],
        ];
    
        tableData.forEach((row) => {
            doc.setFont("helvetica", "bold");
            doc.text(`${row[0]}:`, 10, yPosition); 
            doc.setFont("helvetica", "normal");
            doc.text(row[1].toString(), 70, yPosition); 
            yPosition += 8;
        });
    
        // Total Paid Amount in Words
        const paidAmountWords = numberToWords(paidAmount);
        yPosition += 10;
        doc.setFont("helvetica", "bold");
        doc.text("Total Paid Amount (In Words):", 10, yPosition);
        doc.setFont("helvetica", "normal");
        doc.text(`${paidAmountWords} Only`, 70, yPosition);
    
        // Footer Line
        yPosition += 20;
        doc.line(10, yPosition, 200, yPosition);
    
        // Signatures Section
        yPosition += 10;
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Account Officer", 50, yPosition);
        doc.text("Receiver Officer", 150, yPosition);
        yPosition += 20;
    
        // Signature Lines
        doc.setLineWidth(0.5);
        doc.line(40, yPosition, 90, yPosition); // Account Officer Line
        doc.line(140, yPosition, 190, yPosition); // Receiver Officer Line
    
        // Footer Note
        yPosition += 10;
        doc.setFontSize(10);
        doc.setFont("helvetica", "italic");
        doc.text("Thank you for your payment!", 105, yPosition, null, null, 'center');
        yPosition += 6;
        doc.text("For support, contact us at +123-456-7890 or info@airbook.com", 105, yPosition, null, null, 'center');
    
        // Save PDF
        doc.save(`payment-receipt-${selectedPrehajj.umrahName || 'N/A'}.pdf`);
    };
    
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="PRE HAJJ PAYMENT" subtitle="Managing the Pre Hajj Payment List" />
            </Box>

            {/* Search Box */}
            <Box display="flex" justifyContent="flex-end" sx={{ padding: "5px 10px", width: "auto" }}>
                <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="4px" sx={{ width: 250, padding: "5px 10px" }}>
                    <InputBase
                        sx={{ flex: 1, borderRadius: "4px", padding: "5px 10px" }}
                        placeholder="Search pre-hajj"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box mt="20px">
                <div className="table-responsive">
                    <table className="table table-dark">
                        <thead>
                            <tr>
                                <th>S No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Voucher No</th>
                                <th scope="col">Payment Type</th>
                                <th scope="col">Depositer Name</th>
                                <th scope="col">Bank Name</th>
                                <th scope="col">Cheque No </th>
                                <th scope="col">Amount </th>
                                <th scope="col">Status </th>
                                <th scope="col">Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPrehajj.length > 0 ? (
                                filteredPrehajj.map((prehajj, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{prehajj.umrahName}</td>
                                        <td>{prehajj.voucherNo}</td>
                                        <td>{prehajj.paymentType}</td>
                                        <td>{prehajj.depositerName}</td>
                                        <td>{prehajj.bankName}</td>
                                        <td>{prehajj.ChequeNo}</td>
                                        <td>{prehajj.paidAmount}</td>
                                        <td>{prehajj.status}</td>
                                        <td>
                                            <Button onClick={() => handlePrint(prehajj)}><PrintIcon /></Button>
                                            <EditIcon onClick={() => openUpdateDialogHandler(prehajj)} style={{ cursor: 'pointer', marginRight: 8 }} />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">No data found matching your search criteria</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Update Status Modal */}
            <Dialog
                open={updateModalOpen}
                onClose={() => setUpdateModalOpen(false)}
                maxWidth="xs"
                fullWidth={true}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 6,
                        padding: 2,
                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                    Update Status
                </DialogTitle>

                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
                    <FormControl component="fieldset" fullWidth sx={{ marginTop: 2 }}>
                        <RadioGroup
                            row
                            value={groupDetails.status || ""}
                            onChange={(e) =>
                                setGroupDetails({ ...groupDetails, status: e.target.value })
                            }
                            sx={{ justifyContent: 'center', gap: 2 }}
                        >
                            <FormControlLabel
                                value="Unpaid"
                                control={<Radio />}
                                label="Unpaid"
                                sx={{ color: '#555' }}
                            />
                            <FormControlLabel
                                value="Paid"
                                control={<Radio />}
                                label="Paid"
                                sx={{ color: '#555' }}
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'center', padding: 2 }}>
                    <Button
                        onClick={handleUpdate}
                        variant="contained"
                        color="primary"
                        sx={{ marginX: 1, paddingX: 3, borderRadius: 4 }}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={() => setUpdateModalOpen(false)}
                        variant="outlined"
                        color="secondary"
                        sx={{ marginX: 1, paddingX: 3, borderRadius: 4 }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Team;
