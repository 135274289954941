import React, { useEffect, useState } from "react";
import { Box, Button, useTheme, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { message } from "antd";
import { Formik, Field, Form,FieldArray} from 'formik';
import { useSelector, useDispatch } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { getUmrahGroupDetailsList, deleteUmrahGroupDetails, updateUmrahGroupDetails } from "../../apicalls/umrahGroupgedetails";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPackages } from "../../redux/packegSlice"; 
import './index.css';

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataGroup, setGroupData] = useState([]);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const { packages } = useSelector((state) => state.packages || []);
    const dispatch = useDispatch();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [groupDetails, setGroupDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const typeOf = 'Hajj'; 

    const fetchData = async () => {
        try {
            const response = await getUmrahGroupDetailsList(vendorId, typeOf);
            setGroupData(response.data || []);
        } catch (error) {
            console.error("Error fetching group data:", error);
            message.error("Failed to load group data.",);
        }
    };

    const filteredGroups = dataGroup.filter(group => {
        return (
            group.GroupName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            group.NumberOfAssign.toString().includes(searchQuery) ||    
            group.AirlinesNumber.includes(searchQuery)
        );
    });

    const handleDelete = async () => {
        try {
            await deleteUmrahGroupDetails(selectedGroupId);
            message.success("Group deleted successfully!");
            fetchData();
            setDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting group:", error);
            message.error("Failed to delete group.");
        }
    };

    const openDeleteConfirmation = (groupId) => {
        setSelectedGroupId(groupId);
        setDeleteModalOpen(true);
    };

    const openUpdateDialogHandler = (details) => {
        setGroupDetails(details);
        setGroupDetails({
            ...details,
            Packege_id: details.Packege_id?._id || '', 
          });
        setSelectedGroupId(details._id);
        setUpdateModalOpen(true);
    };

    const handleUpdate = async () => {
        try {
            await updateUmrahGroupDetails(selectedGroupId, groupDetails);
            message.success("Group updated successfully!");
            fetchData();
            setUpdateModalOpen(false);  // Close the modal
        } catch (error) {
            console.error("Error updating group:", error);
            message.error("Failed to update group.");
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    
    useEffect(() => {
        if (vendorId) {
            fetchData();
            dispatch(fetchPackages(vendorId, typeOf));
        }
    }, [dispatch, vendorId, typeOf]); 

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="HAJJ GROUP" subtitle="Managing the Hajj Group List" />
                <Button
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                    }}
                >
                    <Link to="/hajjcreategroup" className="link-style">
                     + Add New Hajj Group
                    </Link>
                </Button>
            </Box>

            {/* Search Box */}
            <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Hajj Groups"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Table */}
            <Box
                m="40px 0 0 0"
                height="75vh"
            >
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Group Name</th>
                                <th scope="col">No. of Assign Main Hajj</th>
                                <th scope="col">Year</th>
                                <th scope="col">Package Type</th>
                                <th scope="col">Flight Date</th>
                                <th scope="col">Return Date</th>
                                <th scope="col">Airlines</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredGroups.length > 0 ? filteredGroups.map((group, index) => (
                                <React.Fragment key={group._id}>
                                    <tr data-bs-toggle="collapse" data-bs-target={`#r${index}`} aria-expanded="false" aria-controls={`r${index}`}>
                                        <th scope="row">+</th>
                                        <td>{group.GroupName || `Group Name ${index + 1}`}</td>
                                        <td>{group.NumberOfAssign}</td>
                                        <td>{group.PackageYear || 2024}</td>
                                        <td>{group.Packege_id.PackageName || `Type ${index + 1}`}</td>
                                        <td>{new Date(group.FlightDate || Date.now()).toLocaleDateString()}</td>
                                        <td>{new Date(group.ReturnDate || Date.now()).toLocaleDateString()}</td>
                                        <td>{group.AirlinesNumber}</td>
                                    </tr>
                                    <tr className="collapse accordion-collapse" id={`r${index}`} data-bs-parent=".table">
                                        <td colSpan="8">
                                            <b>Hotel:</b> {group.HotelName?.join(', ')}<br />
                                            <b>Package Duration:</b>{group.PackageDuration}<br />
                                            <b>Remarks:</b>{group.Remarks}<br />
                                            <b>Action: </b>
                                            <EditIcon onClick={() => openUpdateDialogHandler(group)} style={{ cursor: 'pointer', marginRight: 8 }} />
                                            <DeleteIcon
                                                onClick={() => openDeleteConfirmation(group._id)} 
                                                style={{
                                                    cursor: 'pointer',
                                                    color: 'red',       
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )) : (
                                <tr>
                                    <td colSpan="9" className="text-center">No Umrah Hajj Group data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Modal */}
            <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this group?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} variant="contained" color="primary">Yes</Button>
                    <Button onClick={() => setDeleteModalOpen(false)} variant="outlined" color="secondary">No</Button>
                </DialogActions>
            </Dialog>

            {/* Update Group Details Modal */}
            <Dialog open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
                <DialogTitle>Update Group Details</DialogTitle>
                <DialogContent>
                    {/* Group Name Input */}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Group Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.GroupName || ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, GroupName: e.target.value })}
                    />

                    {/* Package Type Dropdown */}
                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                        <InputLabel id="package-label">Package Type</InputLabel>
                        <Select
                            labelId="package-label"
                            value={groupDetails.Packege_id || ''}
                            onChange={(e) => {
                                const selectedPackage = packages.find(p => p._id === e.target.value);
                                setGroupDetails({
                                    ...groupDetails,
                                    Packege_id: e.target.value,
                                    PackageYear: selectedPackage?.PackageYear || '',
                                    PackageDuration: selectedPackage?.PackageDuration || '',
                                });
                            }}
                        >
                            <MenuItem value="">Select one</MenuItem>
                            {Array.isArray(packages) && packages.length > 0 ? (
                                packages.map(packege => (
                                    <MenuItem key={packege._id} value={packege._id}>
                                        {packege.PackageName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No package available</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {/* Year and Package Duration */}
                    <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Package Year"
                            type="text"
                            value={groupDetails.PackageYear || ''}
                            name="PackageYear"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Package Duration"
                            type="number"
                            value={groupDetails.PackageDuration || ''}
                            name="PackageDuration"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>

                    {/* Number of Assigned Main Haji Input */}
                    <TextField
                        margin="dense"
                        label="No. of Assign Main Haji"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.NumberOfAssign || ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, NumberOfAssign: e.target.value })}
                    />

                    {/* Flight Date */}
                    <TextField
                        margin="dense"
                        label="Flight Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.FlightDate ? groupDetails.FlightDate.split('T')[0] : ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, FlightDate: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    {/* Return Date */}
                    <TextField
                        margin="dense"
                        label="Return Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.ReturnDate ? groupDetails.ReturnDate.split('T')[0] : ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, ReturnDate: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    {/* Airlines Number */}
                    <TextField
                        margin="dense"
                        label="Airlines Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.AirlinesNumber || ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, AirlinesNumber: e.target.value })}
                    />

                    {/* Hotel Name */}
                    {groupDetails.HotelName?.map((hotel, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={2} marginBottom={1}>
                            <TextField
                                fullWidth
                                margin="dense"
                                label={`Hotel Name ${index + 1}`}
                                type="text"
                                variant="outlined"
                                value={hotel}
                                onChange={(e) => {
                                    const updatedHotels = [...groupDetails.HotelName];
                                    updatedHotels[index] = e.target.value;
                                    setGroupDetails({ ...groupDetails, HotelName: updatedHotels });
                                }}
                            />
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                    const updatedHotels = groupDetails.HotelName.filter((_, i) => i !== index);
                                    setGroupDetails({ ...groupDetails, HotelName: updatedHotels });
                                }}
                            >
                                Remove
                            </Button>
                        </Box>
                    ))}

                    <Button
                        onClick={() => setGroupDetails({
                            ...groupDetails,
                            HotelName: [...(groupDetails.HotelName || []), ''],
                        })}
                        variant="contained"
                        color="success"
                    >
                        Add More
                    </Button>

                    {/* Remarks */}
                    <TextField
                        margin="dense"
                        label="Remarks"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={groupDetails.Remarks || ''}
                        onChange={(e) => setGroupDetails({ ...groupDetails, Remarks: e.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdate} variant="contained" color="success">
                        Update
                    </Button>
                    <Button onClick={() => setUpdateModalOpen(false)}  variant="outlined" color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default Team;
