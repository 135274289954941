import { 
  Box,
  Button,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { message } from "antd";
import {
  getCustmoreDetailsList,
  deleteCustmoreDetails,
  updateCustmoreDetails,
} from "../../apicalls/customerDetails";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dataCustmore, setCustmoreData] = useState([]);
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : "";

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedCustmoreId, setSelectedCustmoreId] = useState(null);
  const [custmoreDetails, setCustmoreDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const fetchData = async () => {
    try {
      const response = await getCustmoreDetailsList(vendorId);
      setCustmoreData(response.data || []);
    } catch (error) {
      console.error("Error fetching Custmore data:", error);
      message.error("Failed to load Custmore data.");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteCustmoreDetails(selectedCustmoreId);
      message.success("Custmore deleted successfully!");
      fetchData();
      setDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting Custmore:", error);
      message.error("Failed to delete Custmore.");
    }
  };

  const openDeleteConfirmation = (custmoreId) => {
    setSelectedCustmoreId(custmoreId);
    setDeleteModalOpen(true);
  };

  const openUpdateDialogHandler = (custmore) => {
    setCustmoreDetails(custmore);
    setCustmoreDetails({
      ...custmore,
      agentId: custmore.agentId?._id || '', 
      countryId: custmore.countryId?._id || '', 
    });
    setSelectedCustmoreId(custmore._id);
    setUpdateModalOpen(true);
  };

  const handleUpdate = async () => {
    try {
      await updateCustmoreDetails(selectedCustmoreId, custmoreDetails);
      message.success("Custmore updated successfully!");
      fetchData();
      setUpdateModalOpen(false);
    } catch (error) {
      console.error("Error updating Custmore:", error);
      message.error("Failed to update Custmore.");
    }
  };
  const filtereCustmores = dataCustmore.filter(custmore => {
    return (
      custmore.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      custmore.passportNumber.toString().includes(searchQuery) ||    
      custmore.phoneNumber.includes(searchQuery)
    );
 });
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseUpdateModal = () => {
    setUpdateModalOpen(false);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    fetchData();
  }, [vendorId]);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="CUSTMORS" subtitle="Managing the Custmors" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link
              to="/custmorecreate"
              className="text-decoration-none text-white"
            >
              + Create Customers
            </Link>
          </Button>
          &nbsp;
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link
              to="/createCustmore"
              className="text-decoration-none text-white"
            >
              Export to CSV
            </Link>
          </Button>
        </Box>
     
      </Box>
           {/* Search Box */}
           <Box
                display="flex"
                justifyContent="flex-end"  
                sx={{
                    padding: "5px 10px",  
                    width: "auto", 
                }}
            >
                <Box
                    display="flex"  
                    backgroundColor={colors.primary[400]} 
                    borderRadius="4px"
                    sx={{
                        width: 250, 
                        padding: "5px 10px",
                    }}
                >
                    <InputBase
                        sx={{
                            flex: 1,  
                            borderRadius: "4px",
                            padding: "5px 10px", 
                        }}
                        placeholder="Search Custmores"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
      <Box m="40px 0 0 0" height="75vh">
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Passport No.</th>
                <th scope="col">Passport Exp. Date</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Address</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
                {filtereCustmores.length > 0 ? filtereCustmores.map((custmore) => (
                <React.Fragment key={custmore._id}>
                  <tr data-bs-toggle="collapse" data-bs-target={`#collapse-${custmore._id}`}>
                    <th scope="row">+</th>
                   
                  
                    <td>{custmore.fullName}</td>
                    <td>{custmore.phoneNumber}</td>
                    <td>{custmore.passportNumber}</td>
                    <td>{new Date(custmore.passportExpDate || Date.now()).toLocaleDateString()}</td>
                    <td>{new Date(custmore.dateOfBirth || Date.now()).toLocaleDateString()}</td>
                    <td>{custmore.address}</td>
                    <td>{custmore.isActive ? "Active" : "Inactive"}</td>
                  </tr>
                  <tr className="collapse accordion-collapse" id={`collapse-${custmore._id}`}>
                    <td colSpan="9">
                      <b>Agent Name:</b> {custmore.agentId && custmore.agentId.AgentName ? custmore.agentId.AgentName : 'N/A'}<br />
                      <b>Country Tour:</b> {custmore.countryId && custmore.countryId.CountryName ? custmore.countryId.CountryName : 'N/A'}<br />
                      <b>NID No.:</b> {custmore.nidNo || "N/A"}<br />
                      <b>Police Clearance Date:</b> {new Date(custmore.policeClearanceDate || Date.now()).toLocaleDateString()}<br />
                      <b>Medical Test Date:</b> {new Date(custmore.medicalTestDate || Date.now()).toLocaleDateString()}<br />
                      <b>Medical Exp. Date:</b> {new Date(custmore.medicalExpDate || Date.now()).toLocaleDateString()}<br />
                      <b>Medical Report Submitted Date:</b> {new Date(custmore.medicalReportSubmittedDate || Date.now()).toLocaleDateString()}<br />
                      <b>Calling Visa Issue Date:</b> {new Date(custmore.callingVisaIssueDate || Date.now()).toLocaleDateString()}<br />
                      <b>Calling Visa Exp. Date:</b> {new Date(custmore.callingVisaExpDate || Date.now()).toLocaleDateString()}<br />
                      <b>E-Visa Issue Date:</b> {new Date(custmore.eVisaIssueDate || Date.now()).toLocaleDateString()}<br />
                      <b>E-Visa Exp. Date:</b> {new Date(custmore.eVisaExpDate || Date.now()).toLocaleDateString()}<br />
                      <b>High Commission Approval:</b> {custmore.highCommissionApproval || "N/A"}<br />
                      <b>BMET Entry Date:</b> {new Date(custmore.bmetEntryDate || Date.now()).toLocaleDateString()}<br />
                      <b>Fingerprint Status:</b> {custmore.fingerprintStatus || "N/A"}<br />
                      <b>Ticket Status:</b> {custmore.ticketStatus || "N/A"}<br />
                      <b>Flight (Departure Date Time):</b> {new Date(custmore.flightDepartureDateTime || Date.now()).toLocaleDateString()}<br />
                      <b>Flight (Arrival Date Time):</b> {new Date(custmore.flightArrivalDateTime || Date.now()).toLocaleDateString()}<br />
                      <b>Payable Amount:</b> {custmore.payableAmount || "0"}<br />
                      <b>Paid Amount:</b> {custmore.paidAmount || "0"}<br />
                      <b>Payment Status:</b> {custmore.paymentStatus || "N/A"}<br />
                      <b>Action:</b><EditIcon  onClick={() => openUpdateDialogHandler(custmore)} /> 
                        <DeleteIcon onClick={() => openDeleteConfirmation(custmore._id)}  style={{
                            cursor: 'pointer',
                            color: 'red',       
                        }}/> <br />
                    </td>
                  </tr>
                </React.Fragment>
               )) : (
                <tr>
                    <td colSpan="9" className="text-center">No Customers data available</td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Delete Modal */}
      <Dialog open={deleteModalOpen} onClose={handleCloseDeleteModal}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this Custmore?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Update Modal */}
      <Dialog open={updateModalOpen} onClose={handleCloseUpdateModal}>
        <DialogTitle>Update Custmore Details</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={custmoreDetails.fullName || ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, fullName: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="text"
            fullWidth
            variant="outlined"
            value={custmoreDetails.phoneNumber || ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, phoneNumber: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Passport Number"
            type="text"
            fullWidth
            variant="outlined"
            value={custmoreDetails.passportNumber || ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, passportNumber: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Passport Exp. Date"
            type="date"
            fullWidth
            variant="outlined"
            value={custmoreDetails.passportExpDate ? new Date(custmoreDetails.passportExpDate).toISOString().split("T")[0] : ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, passportExpDate: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Date of Birth"
            type="date"
            fullWidth
            variant="outlined"
            value={custmoreDetails.dateOfBirth ? new Date(custmoreDetails.dateOfBirth).toISOString().split("T")[0] : ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, dateOfBirth: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            variant="outlined"
            value={custmoreDetails.address || ""}
            onChange={(e) => setCustmoreDetails({ ...custmoreDetails, address: e.target.value })}
          />
            <TextField
        margin="dense"
        label="NID No."
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.nidNo || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, nidNo: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Country Tour"
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.countryId || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, countryId: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Police Clearance Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.policeClearanceDate ? new Date(custmoreDetails.policeClearanceDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, policeClearanceDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Medical Test Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.medicalTestDate ? new Date(custmoreDetails.medicalTestDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, medicalTestDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Medical Exp. Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.medicalExpDate ? new Date(custmoreDetails.medicalExpDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, medicalExpDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Medical Report Submitted Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.medicalReportSubmittedDate ? new Date(custmoreDetails.medicalReportSubmittedDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, medicalReportSubmittedDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Calling Visa Issue Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.callingVisaIssueDate ? new Date(custmoreDetails.callingVisaIssueDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, callingVisaIssueDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Calling Visa Exp. Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.callingVisaExpDate ? new Date(custmoreDetails.callingVisaExpDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, callingVisaExpDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="E-Visa Issue Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.eVisaIssueDate ? new Date(custmoreDetails.eVisaIssueDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, eVisaIssueDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="E-Visa Exp. Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.eVisaExpDate ? new Date(custmoreDetails.eVisaExpDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, eVisaExpDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="High Commission Approval"
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.highCommissionApproval || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, highCommissionApproval: e.target.value })}
      />
      <TextField
        margin="dense"
        label="BMET Entry Date"
        type="date"
        fullWidth
        variant="outlined"
        value={custmoreDetails.bmetEntryDate ? new Date(custmoreDetails.bmetEntryDate).toISOString().split('T')[0] : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, bmetEntryDate: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Fingerprint Status"
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.fingerprintStatus || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, fingerprintStatus: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Ticket Status"
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.ticketStatus || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, ticketStatus: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Flight (Departure Date Time)"
        type="datetime-local"
        fullWidth
        variant="outlined"
        value={custmoreDetails.flightDepartureDateTime ? new Date(custmoreDetails.flightDepartureDateTime).toISOString().slice(0, 16) : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, flightDepartureDateTime: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Flight (Arrival Date Time)"
        type="datetime-local"
        fullWidth
        variant="outlined"
        value={custmoreDetails.flightArrivalDateTime ? new Date(custmoreDetails.flightArrivalDateTime).toISOString().slice(0, 16) : ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, flightArrivalDateTime: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Payable Amount"
        type="number"
        fullWidth
        variant="outlined"
        value={custmoreDetails.payableAmount || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, payableAmount: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Paid Amount"
        type="number"
        fullWidth
        variant="outlined"
        value={custmoreDetails.paidAmount || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, paidAmount: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Payment Status"
        type="text"
        fullWidth
        variant="outlined"
        value={custmoreDetails.paymentStatus || ''}
        onChange={(e) => setCustmoreDetails({ ...custmoreDetails, paymentStatus: e.target.value })}
      />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateModal} color="primary">Cancel</Button>
          <Button onClick={handleUpdate} color="secondary">Update</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Team;
