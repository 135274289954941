import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu, Modal } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
// import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import GroupsIcon from '@mui/icons-material/Groups';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          width: "290px !important",
        },
        "& .pro-sidebar-inner": {
          // background: `${colors.primary[400]} !important`,
          background: `#e7f4e7 !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]} style={{ display: "flex", alignSelf: "center" }}>
                  <img
                    alt="logo"
                    width="30px"
                    height="30px"
                    src={`../../assets/favicon.ico`}
                    style={{ cursor: "pointer", }}
                  /><span>&nbsp;AIRBOOKS</span>
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Ed Roh
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  VP Fancy Admin
                </Typography>
              </Box>
            </Box>
          )} */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Visitors"
              to="/visitor"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Hajj" component={<Link to="/team" />}
              icon={<HomeWorkIcon />} >
              <MenuItem><Link to="/hajjpreregister">Pre-Registration</Link></MenuItem>
              <MenuItem><Link to="/hajjmainregister">Main-Registration</Link></MenuItem>
              <MenuItem><Link to="/hajjpackage">Packages</Link></MenuItem>
              <MenuItem><Link to="/hajjgroup">Groups</Link></MenuItem>
            </SubMenu>
            <SubMenu
              title="Umrah"
              icon={<HomeWorkIcon />} >
              <MenuItem><Link to="/umrah"> Umrah </Link></MenuItem>
              <MenuItem><Link to="/umrahpackage"> Packages</Link> </MenuItem>
              <MenuItem><Link to="/umrahgroup"> Groups </Link></MenuItem>
            </SubMenu>
            {/* <Item
              title="Students"
              to="/student"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Customers"
              to="/customers"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Embassy"
              icon={<GroupsIcon />} >
              <MenuItem> <Link to="/embassyformlist">Embassy Form List</Link></MenuItem>
              <MenuItem><Link to="/embassy">Embassy List</Link></MenuItem>
            </SubMenu>
            <Item
              title="Visas"
              to="/visaprocessing"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Passports"
              to="/passports"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Ticket Management"
              icon={<ConfirmationNumberOutlinedIcon />} >
              <MenuItem><Link to="/ticketairlines"> Ticketing Airlines List</Link></MenuItem>
              <MenuItem><Link to="/ticketpurchase"> Ticket Purchase</Link></MenuItem>
              <MenuItem><Link to="/ticketsales"> Ticket Sales</Link></MenuItem>
            </SubMenu>
            <SubMenu
              title="Marketing"
              icon={<PollOutlinedIcon />} >
              <MenuItem><Link to="/marketmanagement">SMS Sending System</Link></MenuItem>
              <MenuItem><Link to="/emailsms">Email Sending System</Link></MenuItem>
            </SubMenu>
            <SubMenu
              title="Accounts"
              icon={<PersonAddAltOutlinedIcon />} >
              <MenuItem> <Link to="/paymentprehaji">Pre Hajj Payment</Link> </MenuItem>
              <MenuItem><Link to="/paymentmainhaji"> Main Hajj Payment</Link> </MenuItem>
              <MenuItem><Link to="/paymentumrah"> Umrah Payment </Link></MenuItem>
              {/* <MenuItem><Link to="/paymentstudent"> Student Payment</Link> </MenuItem> */}
              <MenuItem><Link to="/paymentmanpower"> Manpower Payment</Link> </MenuItem>
              <MenuItem><Link to="/paymentvisa"> Visa Payment</Link> </MenuItem>
              <MenuItem><Link to="/paymentpassport"> Passport Payment</Link> </MenuItem>
              <MenuItem><Link to="/paymentagent"> Agent Payment </Link></MenuItem>
              <MenuItem><Link to="/paymentdailyaccount"> Daily Account </Link></MenuItem>
            </SubMenu>
            <SubMenu
              title="Reports"
              icon={<TextSnippetOutlinedIcon />} >
              <MenuItem><Link to="/reportagentpaymentledger"> Agent Ledger </Link></MenuItem>
              <MenuItem><Link to="/reportticketpurchase"> Ticket Purchase</Link> </MenuItem>
              <MenuItem><Link to="/reportticketsales"> Ticket Sales </Link></MenuItem>
              <MenuItem><Link to="/reportvisitor"> Visitors Report</Link> </MenuItem>
              <MenuItem><Link to="/reportpaymentledgermaster"> Master Payment Report </Link></MenuItem>
              <MenuItem> <Link to="/reportallhaji">All Hajji Registration Report</Link></MenuItem>
              <MenuItem><Link to="/reportpaymentledger"> Payment Ledger </Link></MenuItem>
              {/* <MenuItem><Link to="/reportstudent"> Students Report</Link> </MenuItem> */}
              <MenuItem><Link to="/reportmanpower"> Manpower Report</Link> </MenuItem>
              <MenuItem><Link to="/reportbankledger"> Bank Ledger </Link></MenuItem>
              <MenuItem><Link to="/reportpassport"> Passport Report </Link></MenuItem>
              <MenuItem><Link to="/reportmanpowermaster"> Manpower Master Report</Link> </MenuItem>
            </SubMenu>
            <SubMenu 
              title="Settings"
              icon={<SettingsOutlinedIcon />} >
              <MenuItem><Link to="/agentlist"> Agent List</Link></MenuItem>
              <MenuItem><Link to="/banklist"> Bank </Link></MenuItem>
              <MenuItem><Link to="/countrylist"> Country</Link> </MenuItem>
              <MenuItem> <Link to="/supplierinfo">Supplier Info </Link></MenuItem>
              <MenuItem><Link to="/projectsetup"> Project Setup</Link> </MenuItem>
              <MenuItem><Link to="/permissions"> Permissions</Link> </MenuItem>
            </SubMenu>

            {/* <Item
              title="Subscriptions"
              to="/contacts"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="Company Info"
              to="/companyinfo"
              icon={<RoomPreferencesOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Documentation"
              to="/Userinfo"
              icon={<FolderSharedOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Logout"
              to="/logout"
              icon={<LogoutIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Pages
            </Typography> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box >
  );
};

export default Sidebar;
