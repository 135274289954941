import { configureStore } from "@reduxjs/toolkit";
import loadersReducer from "./loadersSlice";
import usersReducer from "./usersSlice";
import agentsReducer from "./agentsSlice";
import packagesReducer from "./packegSlice"
import groupSlice from "./groupSlice";
import countrySlice from "./countrySlice";
import subscribeSlice from "./subscriptionSlice"
import transactionsReducer from './transactionsSlice';
import dashboardReducer from './dashboardSlice';
import supplierReducer from './supplierSlice';
import airlinesReducer from './airlinesSlice';
import permissionReducer from './permissionSlice';


const store = configureStore({
  reducer: {
    loaders: loadersReducer,
    users: usersReducer,
    agents: agentsReducer,
    packages:packagesReducer,
    groups:groupSlice,
    countries:countrySlice,
    subscriptions:subscribeSlice,
    transactions: transactionsReducer,
    dashboard: dashboardReducer,
    suppliers:supplierReducer,
    airlines:airlinesReducer,
    permissions:permissionReducer,
  },
});

export default store;