import { message } from "antd";
import React, { useEffect } from "react";
import { GetCurrentUser } from "../apicalls/users";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Setuser } from "../redux/usersSlice";
import { HidenLoading, ShowLoading } from "../redux/loadersSlice";
function ProtectedRoute({ children, allowedRoles }) {
  const { user } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCurrentUser = async () => {
    try {
      dispatch(ShowLoading());
      const response = await GetCurrentUser();
      if (response.success) {
        dispatch(Setuser(response.data));
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      dispatch(Setuser(null));
      message.error(error.message);
      localStorage.removeItem("token");
      navigate("/login"); 
    } finally {
      dispatch(HidenLoading());
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const pathname = window.location.pathname;
    if (!token && (pathname === "/login" || pathname === "/signup" || pathname === "")) {
      return; 
    }
    if (token) {
      getCurrentUser();
      if( pathname == "/" || pathname == "/home" || pathname == "/signup"){
        window.location.href = "/dashboard";
      }
    } else {
      if (pathname !== "/login" && pathname !== "/signup") {
        return;
       // navigate("/"); 
      }
    }
  }, [navigate, dispatch]);
  if (!user) {
    return null; 
  }

  // You can also add role-based access here (optional)
  // if (!allowedRoles.includes(user.isAdmin)) {
  //   navigate("/unauthorized");
  //   return null;
  // }

  return children; 
}

export default ProtectedRoute;

