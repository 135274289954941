import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormHelperText
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveairlinesticketsalesDetails } from "../../apicalls/airlinesticketsaleDetails"; 
import { message } from "antd";
import { useSelector,useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import   './index.css';

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users);
    const agents = useSelector((state) => state.agents.agents || []);
    const countries = useSelector((state) => state.countries || []);
    const vendorId = user ? user._id : "";

    const handleFormSubmit = async (values, { resetForm }) => {
        try {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            const response = await saveairlinesticketsalesDetails(formData);
            message.success(response.success ? response.message : response.message);
        } catch (error) {
            message.error("An error occurred. Please try again.");
            console.error(error);
        }
        resetForm();
    };

    const initialValues = {
        airlinseId: "",
        invoice: "",
        unitPrice: "",
        numberOfAdult: '',
        numberOfChildren: '',
        numberOfInfant: 0,
        ticketQty: 0,
        payableAmount: 0,
        paymentStatus: "Pending",
        paidAmount: 0,
        dueAmount: 0,
        vendorId: vendorId,
        agentId: "",
    };

    const checkoutSchema = yup.object().shape({
        airlinseId: yup.string().required("Airline name is required"),
        passengerName: yup.string().required("Passenger name is required"),
        ticketNumber: yup.string().required("Ticket Number  is required"),
        invoice: yup.string().required("Invoice is required"),
        numberOfAdult: yup.number().required("Number of adults is required").min(0),
        numberOfChildren: yup.number().required("Number of children is required").min(0),
        numberOfInfant: yup.number().required("Number of infants is required").min(0),
        unitPrice: yup.number().required("Unit price is required").positive(),
        paidAmount: yup.number().min(0).max(yup.ref('payableAmount')).required("Paid amount is required"),
        agentId: yup.string().required("Supplier is required"),
        flightDate: yup.date().required("Flight Date is required"),
        saleDate: yup.date().required("Sale Date is required"),
    });

    return (
        <Box m="20px">
            <Header title="TICKET SALES" subtitle="Create Ticket Sales" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm }) => {
                    const calculatePayableAmount = () => {
                        const totalTickets = Number(values.numberOfAdult) + Number(values.numberOfChildren) + Number(values.numberOfInfant);
                        return totalTickets * Number(values.unitPrice);
                    };

                    const updateCalculations = () => {
                        const payableAmount = calculatePayableAmount();
                        setFieldValue('ticketQty', Number(values.numberOfAdult) + Number(values.numberOfChildren) + Number(values.numberOfInfant));
                        setFieldValue('payableAmount', payableAmount);
                        setFieldValue('dueAmount', payableAmount - values.paidAmount);
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                {['invoice', 'passengerName', 'ticketNumber', 'remark', 'flightDate', 'saleDate'].map((field, index) => (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        variant="filled"
                                        type={field === 'saleDate' || field === 'flightDate' || field === 'date' ? 'date' : 'text'}
                                        label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values[field]}
                                        name={field}
                                        error={touched[field] && Boolean(errors[field])}
                                        helperText={touched[field] && errors[field]}
                                        sx={{ gridColumn: "span 2" }}
                                        InputLabelProps={field === 'flightDate' || field === 'saleDate' ? { shrink: true } : {}}
                                    />
                                ))}
                            </Box>

                            <Box display="grid" gap="30px" mt="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                                {['numberOfAdult', 'numberOfChildren', 'numberOfInfant'].map((field, index) => (
                                    <TextField
                                        key={index}
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            updateCalculations();
                                        }}
                                        value={values[field]}
                                        name={field}
                                        error={touched[field] && Boolean(errors[field])}
                                        helperText={touched[field] && errors[field]}
                                        sx={{ gridColumn: "span 2" }}
                                    />
                                ))}
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Unit Price"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        updateCalculations();
                                    }}
                                    value={values.unitPrice}
                                    name="unitPrice"
                                    error={touched.unitPrice && Boolean(errors.unitPrice)}
                                    helperText={touched.unitPrice && errors.unitPrice}
                                    sx={{ gridColumn: "span 2" }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Payable Amount"
                                    value={values.payableAmount}
                                    name="payableAmount"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{ readOnly: true }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Due Amount"
                                    value={values.dueAmount}
                                    name="dueAmount"
                                    sx={{ gridColumn: "span 2" }}
                                    InputProps={{ readOnly: true }}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="number"
                                    label="Paid Amount"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        const paidAmount = Number(e.target.value);
                                        setFieldValue('paidAmount', paidAmount);
                                        setFieldValue('dueAmount', values.payableAmount - paidAmount);
                                    }}
                                    value={values.paidAmount}
                                    name="paidAmount"
                                    error={touched.paidAmount && Boolean(errors.paidAmount)}
                                    helperText={touched.paidAmount && errors.paidAmount}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            </Box>

                            <Box
                            display="grid"
                            gap="30px"
                            mt="20px"
                            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                            }}
                        >
                            <FormControl
                                fullWidth
                                variant="filled"
                                error={touched.agentId && Boolean(errors.agentId)}
                                sx={{ gridColumn: "span 2" }}  // Adjust for 2 out of 6 columns
                            >
                                <InputLabel id="supplier-select-label">Select Agent</InputLabel>
                                <Select
                                    labelId="supplier-select-label"
                                    value={values.agentId}
                                    onChange={handleChange}
                                    name="agentId"
                                    onBlur={handleBlur}
                                >
                                    {Array.isArray(agents) && agents.length > 0 ? (
                                agents.map(agent => (
                                    <MenuItem key={agent._id} value={agent._id}>
                                        {agent.AgentName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No agents available</MenuItem>
                            )}
                                </Select>
                                {touched.agentId && errors.agentId && (
                                    <FormHelperText>{errors.agentId}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                variant="filled"
                                error={touched.airlinseId && Boolean(errors.airlinseId)}
                                sx={{ gridColumn: "span 2" }}  // Adjust for 2 out of 6 columns
                            >
                                <InputLabel id="airline-select-label">Select Airline</InputLabel>
                                <Select
                                    labelId="airline-select-label"
                                    value={values.airlinseId}
                                    onChange={handleChange}
                                    name="airlinseId"
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value="671481708b5a69d3eae8c8a5">Air India</MenuItem>
                                    <MenuItem value="671481708b5a69d3eae8c8a6">Air India 2</MenuItem>
                                </Select>
                                {touched.airlinseId && errors.airlinseId && (
                                    <FormHelperText>{errors.airlinseId}</FormHelperText>
                                )}
                            </FormControl>

                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        error={!!touched.ticketClass && !!errors.ticketClass}
                                        sx={{ gridColumn: "span 2" }}  // Adjust for 2 out of 6 columns
                                    >
                                        <InputLabel id="ticket-class-select-label">Select Ticket Class</InputLabel>
                                        <Select
                                            labelId="ticket-class-select-label"
                                            value={values.ticketClass}
                                            onChange={handleChange}
                                            name="ticketClass"
                                            onBlur={handleBlur}
                                        >
                                            <MenuItem value="Economy">Economy </MenuItem>
                                            <MenuItem value="Business">Business </MenuItem>
                                            <MenuItem value="First">First Class</MenuItem>
                                        </Select>
                                        {touched.ticketClass && errors.ticketClass && (
                                            <FormHelperText>{errors.ticketClass}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>

                   

                            <Box display="flex" justifyContent="end" mt="20px">
                            <Button
                            type="button"
                            color="primary" 
                            variant="contained"
                            style={{
                            backgroundColor: '#0b65e9', 
                            color: '#fafafa !important', 
                            borderColor: '#1976d2',
                            }}
                        >
                            <Link to="/ticketsales" className="link-style">
                            Cancel
                            </Link>
                          </Button>&nbsp;&nbsp;
                                <Button type="submit" color="secondary" variant="contained">Create New Airline Ticket</Button>&nbsp;&nbsp;
                                <Button  className="btn-reset" type="button" color="primary" variant="contained" onClick={resetForm} style={{
                                    backgroundColor: '#e2e2e2', 
                                    color: '#1976d2',
                                    borderColor: '#1976d2', 
                                  }}>Reset</Button>
                            </Box>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default Form;
