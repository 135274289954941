
import React, { useEffect } from 'react';
import { Form, message } from "antd";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { LoginUser } from '../../apicalls/users';
import { Link } from 'react-router-dom'; 
import './Login.css'; 

const Login = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      const response = await LoginUser(values);
      if (response.success) {
        message.success(response.message);
        localStorage.setItem('token', response.data);
        // localStorage.setItem('subdomain', response.data.subDomain);
        //  localStorage.setItem('subdomain', 'dilip');
        //  const currentSubdomain = localStorage.getItem("subdomain");
         window.location.href = "/dashboard";
        // window.location.href = `http://${currentSubdomain}.localhost:3000/dashboard`;
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div className="landing-page">
    {/* Navbar */}
    <header className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#e7f4e7' }}>
    <div className="container">
      <Link className="navbar-brand fs-3 fw-bold text-success" to="/">Airbook</Link>
      <div className="d-flex ms-auto">
        <Link to="/login" className="btn btn-outline-success me-2">Login</Link>
        <Link to="/signup" className="btn btn-success">Signup</Link>
      </div>
       </div>
    </header>
    <div className="container-fluid h-100 d-flex align-items-center justify-content-center" style={{ marginTop: '10px' }}>
      <div className="card p-4 w-50 max-w-500" style={{ backgroundColor: '#e7f4e7'}}>
        <h1 className="text-xl mb-3">Airbook- Login</h1>
        <hr />
        <Form layout="vertical" className="mt-1" onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <input type="email" className="form-control" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <input type="password" className="form-control" />
          </Form.Item>

          <div className="flex flex-col mt-3 gap-2">
            <Button fullWidth title="LOGIN" type="submit" />
          </div>
        </Form>
      </div>
      </div>
        
         {/* Footer */}
         <footer className="text-center py-4" style={{ backgroundColor: '#e7f4e7' }}>
        <div className="container">
          <p className="text-muted">&copy; 2024 Airbook. All rights reserved.</p>
          <div className="d-flex justify-content-center">
            <Link to="#" className="text-muted mx-2">Privacy Policy</Link>
            <Link to="#" className="text-muted mx-2">Terms of Service</Link>
            <Link to="#" className="text-muted mx-2">Contact Us</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Login;

