import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useTheme, Modal, TextField, Radio, RadioGroup, FormControlLabel, IconButton, InputBase, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import SearchIcon from "@mui/icons-material/Search";
import { getPackageDetailsList, deletePackageDetails, updatePackageDetails } from "../../apicalls/packegedetails";
import { useSelector } from "react-redux";
import { message } from "antd";
import Header from "../../components/Header";
import DeleteIcon from '@mui/icons-material/Delete';

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [dataPackage, setPackageData] = useState([]);
    const { user } = useSelector((state) => state.users);
    const vendorId = user ? user._id : "";
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [packageDetails, setPackageDetails] = useState({});
    const [searchQuery, setSearchQuery] = useState("");

    // Fetch data from the API
    const fetchData = async () => {
        try {
            const typeOf = 'Hajj';
            const response = await getPackageDetailsList(vendorId, typeOf);
            setPackageData(response.data || []);
        } catch (error) {
            console.error("Error fetching package data:", error);
            message.error("Failed to load package data.");
        }
    };

    // Handle deletion of a package
    const handleDelete = async () => {
        try {
            await deletePackageDetails(selectedPackageId);
            message.success("Package deleted successfully!");
            fetchData();
            setDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting package:", error);
            message.error("Failed to delete package.");
        }
    };

    // Open delete confirmation modal
    const openDeleteConfirmation = (packageId) => {
        setSelectedPackageId(packageId);
        setDeleteModalOpen(true);
    };

    // Open update modal with prefilled details
    const openUpdateDialogHandler = (packageDetails) => {
        setPackageDetails(packageDetails);
        setSelectedPackageId(packageDetails._id);
        setUpdateModalOpen(true);
    };

    // Filter packages based on the search query
    const filteredPackageData = dataPackage.filter((Package) => {
        const searchValue = searchQuery.toLowerCase();
        return (
            Package.PackageName.toLowerCase().includes(searchValue) ||
            Package.PackagePurpose.toLowerCase().includes(searchValue) ||
            Package.PackageDate.includes(searchQuery) ||
            Package.Remarks.toLowerCase().includes(searchValue) ||
            (Package.PackageYear && Package.PackageYear.toString().includes(searchQuery)) ||
            (Package.PackageAmount && Package.PackageAmount.toString().includes(searchQuery))
        );
    });

    // Handle the update of package details
    const handleUpdate = async () => {
        const { PackageName, PackageDuration, PackagePurpose, PackageDate, PackageYear, PackageAmount } = packageDetails;

        if (!PackageName || !PackagePurpose || !PackageDate || !PackageYear || !PackageAmount || !PackageDuration) {
            message.error("All fields are required!");
            return;
        }

        if (isNaN(PackageYear) || isNaN(PackageAmount) || isNaN(PackageDuration)) {
            message.error("Years and Amount must be valid numbers!");
            return;
        }

        if (Number(PackageYear) <= 0 || Number(PackageAmount) <= 0 || Number(PackageDuration) <= 0) {
            message.error("Years and Amount must be positive numbers!");
            return;
        }

        if (!/^\d{1,4}$/.test(PackageYear)) {
            message.error("Years must be a maximum of four digits!");
            return;
        }

        try {
            await updatePackageDetails(selectedPackageId, packageDetails);
            message.success("Package updated successfully!");
            fetchData();
            setUpdateModalOpen(false);
        } catch (error) {
            console.error("Error updating package:", error);
            message.error("Failed to update package.");
        }
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        fetchData();
    }, [vendorId]);
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear + i);
    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="HAJJ PACKAGE" subtitle="Managing the Hajj Package List" />
                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/hajjcreatepackage" className="link-style">
                            + Create Hajj Package
                        </Link>
                    </Button>
                </Box>
            </Box>

            {/* Search Box positioned to the right of the table */}
            <Box display="flex" justifyContent="flex-end" sx={{ padding: "5px 10px", width: "auto" }}>
                <Box display="flex" backgroundColor={colors.primary[400]} borderRadius="4px" sx={{ width: 250, padding: "5px 10px" }}>
                    <InputBase
                        sx={{ flex: 1, borderRadius: "4px", padding: "5px 10px" }}
                        placeholder="Search  Hajj package"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>

            <Box m="40px 0 0 0" height="75vh">
                <div className="table-responsive">
                    <table className="table accordion table-dark">
                        <thead>
                            <tr>
                                <th scope="col">S. No.</th>
                                <th scope="col">Package Name</th>
                                <th scope="col">Package Type</th>
                                <th scope="col">Date</th>
                                <th scope="col">Years</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Remarks</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPackageData.length > 0 ? filteredPackageData.map((pack, index) => (
                                <tr key={pack._id}> {/* Ensure unique key */}
                                    <th scope="row">{index + 1}</th>
                                    <td>{pack.PackageName}</td>
                                    <td>{pack.PackagePurpose}</td>
                                    <td>{pack.PackageDate?.substring(0, 10) || ''}</td>
                                    <td>{pack.PackageYear}</td>
                                    <td>Rs {parseFloat(pack.PackageAmount).toFixed(2)}</td>
                                    <td>{pack.Remarks}</td>
                                    <td>{pack.isActive}</td>
                                    <td>
                                        <EditIcon onClick={() => openUpdateDialogHandler(pack)} style={{ cursor: 'pointer' }} />
                                        <DeleteIcon
                                            onClick={() => openDeleteConfirmation(pack._id)}
                                            style={{
                                                cursor: 'pointer',
                                                color: 'red',
                                               
                                            }}
                                        />
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="9" className="text-center">No Umrah Hajj Package data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </Box>

            {/* Delete Confirmation Modal */}
            <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
                <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '300px' }}>
                    <Typography variant="h6">Are you sure you want to delete this package?</Typography>
                    <Box mt={2}>
                        <Button onClick={handleDelete} color="error">Delete</Button>
                        <Button onClick={() => setDeleteModalOpen(false)} color="primary" sx={{ ml: 2 }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>

            {/* Update Modal */}
            <Modal open={updateModalOpen} onClose={() => setUpdateModalOpen(false)}>
                <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px' }}>
                    <Typography variant="h6">Update Package Details</Typography>
                    <TextField
                        label="Package Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={packageDetails.PackageName || ''}
                        onChange={(e) => setPackageDetails({ ...packageDetails, PackageName: e.target.value })}
                    />
                    <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
                        <InputLabel id="package-purpose-label">Package Type</InputLabel>
                        <Select
                            labelId="package-purpose-label"
                            value={packageDetails.PackagePurpose || ""}
                            onChange={(e) => setPackageDetails({ ...packageDetails, PackagePurpose: e.target.value })}
                        >
                            <MenuItem value="VIP Package">VIP Package</MenuItem>
                            <MenuItem value="Standard Package">Standard Package</MenuItem>
                            <MenuItem value="Economy Package">Economy Package</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Date"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="date"
                        value={packageDetails.PackageDate?.substring(0, 10) || ''}
                        onChange={(e) => setPackageDetails({ ...packageDetails, PackageDate: e.target.value })}
                    />
                   
                   <FormControl fullWidth variant="filled" sx={{ mt: 2 }}>
                    <InputLabel id="package-year-label">Package Year</InputLabel>
                    <Select
                        labelId="package-year-label"
                        value={packageDetails.PackageYear || ""}
                        onChange={(e) => setPackageDetails({ ...packageDetails, PackageYear: e.target.value })} // Corrected this line
                    >
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                   
                    <TextField
                        label="Total Amount"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={packageDetails.PackageAmount || ''}
                        onChange={(e) => setPackageDetails({ ...packageDetails, PackageAmount: e.target.value })}
                    />
                    <TextField
                        label="Remarks"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={packageDetails.Remarks || ''}
                        onChange={(e) => setPackageDetails({ ...packageDetails, Remarks: e.target.value })}
                    />
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Status</Typography>
                    <RadioGroup
                        row
                        value={packageDetails.isActive || 'Inactive'}
                        onChange={(e) => setPackageDetails({ ...packageDetails, isActive: e.target.value })}
                    >
                        <FormControlLabel value="Active" control={<Radio />} label="Active" />
                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                    </RadioGroup>

                    <Box mt={2}>
                        <Button onClick={handleUpdate} color="primary">Update</Button>
                        <Button onClick={() => setUpdateModalOpen(false)} color="secondary" sx={{ ml: 2 }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default Team;
