import { Box, Button, Radio, FormControlLabel, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { saveBankDetails } from "../../apicalls/bankdetails";
import { message } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import   './index.css';

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 
    const checkoutSchema = yup.object().shape({
        BankName: yup.string().required("Required"),
        BranchName: yup.string().required("Required"),
        Description: yup.string().required("Required"),
        Address: yup.string().required("Required"),
    });

    const initialValues = {
        BankName: "",
        BranchName: "",
        Description: "",
        Address: "",
        vendor_id: vendorId,
        isActive: "Active", 
    };

    const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
        try {
            await saveBankDetails(formData); 
            message.success("Bank details saved successfully!");
            resetForm(); 
        } catch (error) {
            message.error("Error saving bank details. Please try again.");
        } finally {
            setSubmitting(false); 
        }
    };

    return (
        <Box m="20px">
            <Header title="ADD BANK" subtitle="Add a New Bank" />

            <Formik
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Bank Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.BankName}
                                name="BankName"
                                error={touched.BankName && !!errors.BankName}
                                helperText={touched.BankName && errors.BankName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Branch Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.BranchName}
                                name="BranchName"
                                error={touched.BranchName && !!errors.BranchName}
                                helperText={touched.BranchName && errors.BranchName}
                                sx={{ gridColumn: "span 2" }}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                label="Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Address}
                                name="Address"
                                error={touched.Address && !!errors.Address}
                                helperText={touched.Address && errors.Address}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Description"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Description}
                                name="Description"
                                error={touched.Description && !!errors.Description}
                                helperText={touched.Description && errors.Description}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>

                        <h5 className="mt-3">Status</h5>
                        <Box display="flex" flexDirection="row">
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={values.status === 'active'}
                                        onChange={handleChange}
                                        value="active"
                                        name="status"
                                        inputProps={{ 'aria-label': 'Active' }}
                                    />
                                }
                                label="Active"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={values.status === 'inactive'}
                                        onChange={handleChange}
                                        value="inactive"
                                        name="status"
                                        inputProps={{ 'aria-label': 'Inactive' }}
                                    />
                                }
                                label="Inactive"
                            />
                        </Box>

                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            type="button"
                            color="primary" 
                            variant="contained"
                            style={{
                            backgroundColor: '#0b65e9', 
                            color: '#fafafa !important', 
                            borderColor: '#1976d2',
                            }}
                        >
                            <Link to="/banklist" className="link-style">
                            Cancel
                            </Link>
                          </Button>&nbsp;&nbsp;
                            <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
                                Submit
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
