import { Box, Button, Radio, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import { message } from "antd";
import { saveSupplierDetails } from "../../apicalls/supplierdetails";
import { Link } from "react-router-dom";
import   './index.css';

const Form = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const { user } = useSelector((state) => state.users); 
    const vendorId = user ? user._id : ""; 

    const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const checkoutSchema = yup.object().shape({
        SupplierName: yup.string().required("Required"),
        SupplierEmail: yup.string().email("Invalid email").required("Required"),
        SupplierNumber: yup.string().matches(phoneRegExp, "Phone number is not valid").required("Required"),
        Address: yup.string().required("Required"),
        NIDNumber: yup.string().required("Required"),
        VATNumber: yup.string().required("Required"),
    });

    const initialValues = {
        SupplierName: "",
        SupplierNumber: "",
        Address: "",
        NIDNumber: "",
        SupplierEmail: "",
        VATNumber: "",
        vendor_id: vendorId,
        status: "active", 
    };

    const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });

        try {
            await saveSupplierDetails(formData); 
            message.success("Supplier details saved successfully!");
            resetForm(); 
        } catch (error) {
            console.error("Error saving supplier details:", error);
            message.error("Error saving supplier details. Please try again.");
        } finally {
            setSubmitting(false); 
        }
    };

    return (
        <Box m="20px">
            <Header title="CREATE SUPPLIER" subtitle="Create a New Supplier" />

            <Formik
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                onSubmit={handleFormSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.SupplierName}
                                name="SupplierName"
                                error={!!touched.SupplierName && !!errors.SupplierName}
                                helperText={touched.SupplierName && errors.SupplierName}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="email"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.SupplierEmail}
                                name="SupplierEmail"
                                error={!!touched.SupplierEmail && !!errors.SupplierEmail}
                                helperText={touched.SupplierEmail && errors.SupplierEmail}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Mobile Number"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.SupplierNumber}
                                name="SupplierNumber"
                                error={!!touched.SupplierNumber && !!errors.SupplierNumber}
                                helperText={touched.SupplierNumber && errors.SupplierNumber}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="Present Address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.Address}
                                name="Address"
                                error={!!touched.Address && !!errors.Address}
                                helperText={touched.Address && errors.Address}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="NID No."
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.NIDNumber}
                                name="NIDNumber"
                                error={!!touched.NIDNumber && !!errors.NIDNumber}
                                helperText={touched.NIDNumber && errors.NIDNumber}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                label="VAT No. (BIN)"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.VATNumber}
                                name="VATNumber"
                                error={!!touched.VATNumber && !!errors.VATNumber}
                                helperText={touched.VATNumber && errors.VATNumber}
                                sx={{ gridColumn: "span 2" }}
                            />
                        </Box>

                        <h5 className="mt-3">Status</h5>
                        <Radio
                            checked={values.status === 'active'}
                            onChange={handleChange}
                            value="active"
                            name="status"
                            inputProps={{ 'aria-label': 'Active' }}
                        /> Active
                        <Radio
                            checked={values.status === 'inactive'}
                            onChange={handleChange}
                            value="inactive"
                            name="status"
                            inputProps={{ 'aria-label': 'Inactive' }}
                        /> Inactive

                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button
                            type="button"
                            color="primary" 
                            variant="contained"
                            style={{
                            backgroundColor: '#0b65e9', 
                            color: '#fafafa !important', 
                            borderColor: '#1976d2',
                            }}
                        >
                            <Link to="/supplierinfo" className="link-style">
                            Cancel
                            </Link>
                          </Button>&nbsp;&nbsp;
                            <Button type="submit" color="secondary" variant="contained">
                                Submit
                            </Button>&nbsp;&nbsp;
                            <Button type="button" color="primary" variant="contained" onClick={resetForm}  style={{
                                    backgroundColor: '#e2e2e2', 
                                    color: '#1976d2',
                                    borderColor: '#1976d2', 
                                  }}>
                                Reset
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default Form;
