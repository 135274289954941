import React, { useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import { Link } from 'react-router-dom';    
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { message } from "antd";
import { saveUmrahGroupDetails } from "../../apicalls/umrahGroupgedetails"; 
import { useSelector, useDispatch } from "react-redux";
import { fetchPackages } from "../../redux/packegSlice"; 
import './index.css'

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { user } = useSelector((state) => state.users);
  const vendorId = user ? user._id : ""; 
  const { packages } = useSelector((state) => state.packages || []);
  const dispatch = useDispatch();
  const typeOf = 'Umrah'; 

  useEffect(() => {
    if (vendorId) {
      dispatch(fetchPackages(vendorId, typeOf));
    }
  }, [dispatch, vendorId, typeOf]); 
  

  const checkoutSchema = yup.object().shape({
    GroupName: yup.string().required("Group name is required"),
    FlightDate: yup
      .date()
      .required("Flight date is required")
      .nullable()
      .test("is-greater", "Flight date must be before return date", function (value) {
        const { ReturnDate } = this.parent;
        return value && ReturnDate ? value <= ReturnDate : true;
      }),
    ReturnDate: yup.date().required("Return date is required").nullable(),
    NumberOfAssign: yup
      .number()
      .positive("Number Of Assign must be a  number")
      .required("Number Of Assign is required"),
    Packege_id: yup.string().required("Package purpose is required"),
    Remarks: yup.string().required("Remarks is required"),
    AirlinesNumber: yup.string().required("Airlines Number is required"),
    HotelNames: yup.array().of(yup.string().required("Hotel Name is required")),
  });

  const initialValues = {
    GroupName: "",
    FlightDate: null,
    NumberOfAssign: "",
    Remarks: "",
    Packege_id: "", 
    TypeOfGroup: "Umrah", 
    AirlinesNumber: "",
    // vendor_id: vendorId,
    isActive: "Active",
    HotelNames: [""], 
  };

  const handleFormSubmit = async (values, { resetForm, setSubmitting }) => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    try {
      formData.append("vendor_id", vendorId);
      await saveUmrahGroupDetails(formData);
      message.success("Package details saved successfully!");
      resetForm();
    } catch (err) { 
      console.error("Error saving package details:", err); 
      message.error("Error saving package details. Please try again.", err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m="20px">
      <Header title="CREATE UMRAH GROUP" subtitle="Create a New Umrah Group" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* Group Name */}
              <TextField
                fullWidth
                variant="filled"
                label="Group Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GroupName}
                name="GroupName"
                error={!!touched.GroupName && !!errors.GroupName}
                helperText={touched.GroupName && errors.GroupName}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Package Type */}
              <FormControl fullWidth variant="filled" error={!!touched.Packege_id && !!errors.Packege_id} sx={{ gridColumn: "span 2" }}>
                <InputLabel id="package-label">Package Type</InputLabel>
                <Select
                  labelId="package-label"
                  value={values.Packege_id}
                  onChange={(e) => {
                    const selectedPackage = packages.find(p => p._id === e.target.value);
                    setFieldValue('Packege_id', e.target.value);
                    setFieldValue('PackageYear', selectedPackage?.PackageYear || ''); 
                    setFieldValue('PackageDuration', selectedPackage?.PackageDuration || ''); 
                  }}
                  name="Packege_id"
                  onBlur={handleBlur}
                >
                  <MenuItem value="">
                    Select one
                  </MenuItem>
                  {Array.isArray(packages) && packages.length > 0 ? (
                    packages.map(packege => (
                      <MenuItem key={packege._id} value={packege._id}>
                        {packege.PackageName} 
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No package available</MenuItem>
                  )}
                </Select>
                {touched.Packege_id && errors.Packege_id && (
                  <FormHelperText>{errors.Packege_id}</FormHelperText>
                )}
              </FormControl>

              {/* Year */}
          
              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                {/* Year (Package Year) */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Package Year"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }} 
                  value={values.PackageYear || ''}
                  name="PackageYear"
                  error={!!touched.PackageYear && !!errors.PackageYear}
                  helperText={touched.PackageYear && errors.PackageYear}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true, 
                  }}
                />
                {/* Package Duration */}
                <TextField
                  fullWidth
                  variant="filled"
                  label="Package Duration"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PackageDuration||''}
                  InputLabelProps={{ shrink: true }} 
                  name="PackageDuration"
                  error={!!touched.PackageDuration && !!errors.PackageDuration}
                  helperText={touched.PackageDuration && errors.PackageDuration}
                  sx={{ gridColumn: "span 2" }}
                  InputProps={{
                    readOnly: true, // Make it read-only because it's auto-filled from selected package
                  }}
                />
              </Box>

              {/* Flight Date */}
              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Flight Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('FlightDate', dateValue);
                  }}
                  value={values.FlightDate ? values.FlightDate.toISOString().split('T')[0] : ''}
                  name="FlightDate"
                  error={!!touched.FlightDate && !!errors.FlightDate}
                  helperText={touched.FlightDate && errors.FlightDate}
                />
              </Box>

              {/* Return Date */}
              <Box sx={{ gridColumn: "span 2", display: 'flex', gap: '20px' }}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Return Date"
                  InputLabelProps={{ shrink: true }} 
                  onBlur={handleBlur}
                  onChange={(e) => {
                    const dateValue = new Date(e.target.value);
                    setFieldValue('ReturnDate', dateValue);
                  }}
                  value={values.ReturnDate ? values.ReturnDate.toISOString().split('T')[0] : ''}
                  name="ReturnDate"
                  error={!!touched.ReturnDate && !!errors.ReturnDate}
                  helperText={touched.ReturnDate && errors.ReturnDate}
                />
              </Box>

              {/* Number of Assign */}
              <TextField
                fullWidth
                variant="filled"
                label="No. of Assign Main Haji"
                type="number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.NumberOfAssign}
                name="NumberOfAssign"
                error={!!touched.NumberOfAssign && !!errors.NumberOfAssign}
                helperText={touched.NumberOfAssign && errors.NumberOfAssign}
                sx={{ gridColumn: "span 2" }}
              />
              {/* Airlines Number */}
              <TextField
                fullWidth
                variant="filled"
                label="Airlines"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.AirlinesNumber}
                name="AirlinesNumber"
                error={!!touched.AirlinesNumber && !!errors.AirlinesNumber}
                helperText={touched.AirlinesNumber && errors.AirlinesNumber}
                multiline
                sx={{ gridColumn: "span 2" }}
              />

          <TextField
                fullWidth
                variant="filled"
                label="Remarks"
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.Remarks}
                name="Remarks"
                error={!!touched.Remarks && !!errors.Remarks}
                helperText={touched.Remarks && errors.Remarks}
                sx={{ gridColumn: "span 2" }}
              />

              {/* Hotel Names */}
              <FieldArray name="HotelNames">
              {({ push, remove }) => (
                <>
                  {Array.isArray(values.HotelNames) && values.HotelNames.map((hotelName, index) => (
                    <Box sx={{ gridColumn: "span 4", display: 'flex', gap: '20px' }} key={index}>
                      <TextField
                        fullWidth
                        variant="filled"
                        label="Hotel Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={hotelName}
                        name={`HotelNames[${index}]`}
                        error={!!touched.HotelNames && !!errors.HotelNames?.[index]}
                        helperText={touched.HotelNames && errors.HotelNames?.[index]}
                        multiline
                      />
                      <Button
                        type="button"
                        color="#f4062d"
                        variant="standard"
                        onClick={() => remove(index)} 
                        disabled={values.HotelNames.length === 1} 
                      >
                        Remove
                      </Button>
                      
                    </Box>
                  ))}
                  <Box display="flex"  mt="20px">
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={() => push("")} 
                  >
                    Add More
                  </Button>
                  </Box>
                </>
              )}
            </FieldArray>
             <Box  sx={{ gridColumn: "span 4"}} display="flex" justifyContent="end" mt="20px">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  style={{
                    backgroundColor: '#0b65e9', 
                    color: '#fafafa !important', 
                    borderColor: '#1976d2',
                  }}
                >
                  <Link to="/umrahgroup" className="link-style">
                    Cancel
                  </Link>
                </Button>

                &nbsp;&nbsp;
                <Button type="submit" color="secondary" variant="contained">
                  Create New Umrah Group
                </Button>
                &nbsp;&nbsp;
                <Button 
                  type="button"
                  color="primary" 
                  variant="contained"
                  onClick={resetForm}
                  style={{
                    backgroundColor: '#e2e2e2', 
                    color: '#141414',
                    borderColor: '#1976d2', 
                  }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
